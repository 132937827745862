.MenuVehicles {
    background-color: #eeeeee;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    .MenuVehicles-bar {
        background-color: $aux-gray1;
        display: flex;
        justify-content: center;
        height: 70px;
        position: relative;

        &::after {
            background-color: #dedede;
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        a {
            font-size: em(11px);
            font-weight: 700;
            text-transform: uppercase;
            margin: 0 10px;
            display: flex;
            align-items: center;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: em(14px);
                margin: 0 20px;

                br {
                    display: none;
                }
            }
        }

        .close-btn {
            background-color: transparent;
            background-image: url(/images/icon-close.svg);
            background-position: right top;
            background-size: 100% auto;
            border: 0;
            height: 42px;
            width: 42px;
            right: 23px;
            top: 14px;
            position: absolute;

            @include media-breakpoint-up(lg) {
                right: 39px;
            }
        }
    }
}

.MenuVehicles-all {
    overflow-x: hidden;
    .close-btn {
        background-color: transparent;
        background-image: url(/images/icon-close.svg);
        background-position: right top;
        background-size: 100% auto;
        border: 0;
        height: 42px;
        width: 42px;
        right: 12px;
        top: -19px;
        position: absolute;
        transform: scale(0.8);
        z-index: 2;
    }
    >.container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.Vehicles-type {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 20px;

    @include media-breakpoint-up(md) {
        margin-bottom: 10px;
    }
}

.MenuVehicles_types {
    position: relative;
    @include media-breakpoint-up(md) {
        justify-content: center;
    }
}

.VehicleItem {
    margin-bottom: 25px;
    margin-top: 25px;
    position: relative;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }

    .carsCategory {
        color: #848484;
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: em(13px);

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    .VehicleItem-box {
        .wrap-buttons {
            margin-top: 10px;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }

            a {
                background-color: transparent;
                border: 1px solid;
                height: 22px;
                line-height: 0;
                min-width: 110px;
                max-width: 110px;
                margin-top: 15px;

                @include media-breakpoint-up(lg) {
                    min-width: 85px;
                    margin-top: 0;
                }

                &:hover {
                    background-color: $brand-primary;
                    color: white;
                }
            }
        }
    }
}

.VehicleItem-box {
    text-align: center;
    line-height: normal;

    @include media-breakpoint-up(md) {
        padding: 15px;
    }

    .Vehicle-self {
        display: block;
        //margin-bottom: 10px;

        img {
            @media #{$mq-2k} {
                margin: auto;
            }
        }
    }

    /*
    .Vehicle-nameplate {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 25px;

        img {
            height: 15px;
            width: auto;

            &.max-height {
                height: 25px;
            }
        }

        a {
            color: #5b5b5b;
            font-size: 14px;
            line-height: 1;
            margin: 0 5px;
        }
    }
     */
    .Vehicle-name {
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
            font-size: em(14px);
            margin-top: 5px;
            display: block;
        }

        a {
            color: $brand-third;
        }

        span {
            font-weight: 700;
            margin-left: 5px;

            @include media-breakpoint-down(sm) {
                font-size: em(14px);
            }
        }
    }

    .Vehicle-price {
        color: #848484;
        font-size: em(12px);
        margin: 5px 0 10px 0;

        @include media-breakpoint-up(md) {
            font-size: em(15px);
        }

        span {
            color: #343434;
            font-weight: 700;

            small {
                color: black;
                text-transform: uppercase;
                font-weight: 700;
                font-size: em(15px);

                @include media-breakpoint-up(md) {
                    //font-size: 14px;
                }
            }
        }
    }

    @include hover {
        border-radius: 7px;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
    }

}
