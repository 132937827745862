.shoppingTools {
    background-color: #f6f6f6;
    width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 100%;
        left: 0;
    }

    >.container>.row {
        @include media-breakpoint-only(md) {
            align-items: center;
        }
    }

    .shoppingTools-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px 15px;

        @include media-breakpoint-up(lg) {
            align-items: center;
            border-right: 1px solid #e4e4e4;
            padding: 40px 0;
        }


        .BuildElem {
            margin-bottom: 15px;
            padding: 0 7px;
            width: 50%;

            @include media-breakpoint-up(lg) {
                padding: 0 15px;
                width: 205px;
            }
        }

        h4 {
            font-size: em(14px);

            @include media-breakpoint-up(lg) {
                font-size: em(16px);
            }
        }
    }

    .shoppingTool-link {
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
            padding: 25px 15px;
        }
    }

    .shoppingTools-elem {
        >.row {
            @include media-breakpoint-only(md) {
                background-color: #f0f0f0;
            }
        }

        @include media-breakpoint-up(lg) {
            background-color: #f0f0f0;
            padding-bottom: 60px;
            padding-top: 60px;

            &::after {
                background-color: inherit;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 100%;
            }
        }


        .shoppingTools-elem_item {
            font-size: em(16px);

            &:nth-of-type(1) {
                border-bottom: 1px solid #e4e4e4;
            }

            @include media-breakpoint-only(md) {
                padding-top: 4%;
                padding-bottom: 4%;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 30px;

                &:nth-of-type(1) {
                    border: none;
                    border-right: 1px solid #e4e4e4;
                }
            }

            >a {
                @include media-breakpoint-down(lg) {
                    align-items: center;
                }
            }

            label {
                color: $brand-third;
                font-size: em(20px);
                font-weight: 200;
            }

            span {
                color: #ed1b2f;
                font-size: em(14px);
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }

            figure {
                @include media-breakpoint-up(lg) {
                    max-width: 236px;
                }

                img {
                    mix-blend-mode: multiply;
                }
            }

            .onDesk {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            .shoppingTools-elem_titles {
                @include media-breakpoint-down(lg) {
                    padding-left: 25px;
                }
            }
        }

    }
}
