body {
    &.teaser {

        #content {

            .VehicleHead {
                padding-top: 0;

                .VehicleHead-inner {
                    height: 45%;
                    text-align: center;
                    position: absolute;
                    bottom: 50px;
                    @include centrar(horizontal);
                    z-index: 1;

                    @include media-breakpoint-up(md) {
                        height: calc(100% - 60px);
                        top: 60px;
                        bottom: unset;
                    }

                    @include media-breakpoint-up(lg) {
                        left: 5%;
                        transform: translateX(-5%);
                        height: 100%;
                    }

                    @include media-breakpoint-up(xl) {
                        left: 10%;
                        transform: translateX(-10%);
                    }

                    > .row {
                        height: 100%;

                        @include media-breakpoint-up(md) {
                            height: 100%;
                        }
                    }

                    .ImgTopCRrv {
                        width: 218px;

                        @include media-breakpoint-up(md) {
                            width: 200px;
                            margin-top: -20px;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 312px;
                        }

                        @include media-breakpoint-up(xl) {
                            margin-top: 40px;
                        }

                        @media (min-width: 2000px) {
                            margin-top: 60px;
                            width: 400px;
                        }
                    }

                    .PriceBox {
                        @include media-breakpoint-up(md) {
                            bottom: 11%;
                        }
                    }
                }

                .e-hev {
                    margin: 30px auto 0;
                    width: 80%;

                    @include media-breakpoint-up(md) {
                        width: 250px;
                    }
                    @include media-breakpoint-up(lg) {
                        margin: 30px auto 15px;
                        width: 315px;
                    }
                    @include media-breakpoint-up(xl) {
                        margin: 40px auto 40px;
                    }
                    @media (min-width: 2000px) {
                        width: 490px;
                    }
                }
            }

            .colorTextsWrap {
                .colorInteriorWrap {
                    .wrap-int-color {
                        width: 125px;

                        @media (min-width: 2000px) {
                            width: 250px;
                        }
                    }
                }
            }

            .TestDrive {
                .TextTestDriveBox {
                    margin-top: 30px;
                    margin-bottom: 30px;

                    @include media-breakpoint-up(md) {
                        margin-top: 80px;
                        margin-bottom: 80px;
                    }

                    h3 {
                        height: 60px;

                        @include media-breakpoint-up(lg) {
                            font-size: 46px;
                        }
                        @media (min-width: 2000px) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .contact-form {
                padding-top: 0;
                margin-top: -220px;
                width: 100%;
                max-width: 100%;
                text-align: center;
                display: block;
                position: relative;

                @include media-breakpoint-up(sm) {
                    margin-top: 0;
                    display: flex;
                }
                @include media-breakpoint-up(md) {
                    padding-top: 80px;
                }

                .form-group {
                    margin-right: 20px;
                }

                .BtnStd {
                    @include media-breakpoint-up(sm) {
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }
                    @include media-breakpoint-up(md) {
                        margin-bottom: 0;
                    }
                    @include media-breakpoint-up(xl) {
                        margin-top: 0;
                    }
                }

                .error-fields {
                    position: absolute;
                    left: 0;
                    top: -26px;

                    @include media-breakpoint-up(sm) {
                        top: 45px;
                    }
                    @include media-breakpoint-up(xl) {
                        top: 0;
                        position: relative;
                        margin-right: 27px;
                    }

                    @media (min-width: 1880px) {
                        font-size: 20px;
                        position: absolute;
                        left: 0;
                        top: 140px;
                    }
                }
            }
        }


    }
}
