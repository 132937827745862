.main-survey-wrap {
    background-color: rgb(255,255, 255);
    min-height: 195px;
    border-radius: 10px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;

    &.with-chat {
        bottom: 14%;

        @include media-breakpoint-up(md) {
            bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        background-color: rgb(255,255, 255, 0.9);
        border-radius: 24px;
        width: 636px;
        height: 277px;
        padding: 12px 12px 16px 16px;
        left: 27px;
    }

    label {
        width: 218px;
        height: 32px;
        margin: 15px auto 15px;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #191919;
        display: block;

        @include media-breakpoint-up(md) {
            font-size: 16px;
            text-align: center;
            height: 22px;
            width: 100%;
        }
    }

    .close-survey {
        display: block;
        position: absolute;
        right: 20px;
        width: 24px;
        height: 24px;
        top: 11px;

        @include media-breakpoint-up(md) {
            height: 40px;
            top: 24px;
            right: 10px;
            width: 40px;
        }
    }

    ul {
        margin: 0;
        padding-left: 6%;
        padding-right: 6%;
        width: 100%;
        display: flex;
        align-items: center;
        align-content: space-between;
        flex-wrap: wrap;

        &.w-style {
            border-radius: 0;
            box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
            background: linear-gradient(to left, #cd1526, #ed1b2f);
            height: 39px;

            @include media-breakpoint-up(md) {
                height: 59px;
                border-radius: 15px;
                margin: 0 auto;
                width: 486px;
            }
        }

        li {
            list-style: none;
            text-align: center;
            height: 100%;
            position: relative;
            width: 9%;

            &.selected {
                &:before {
                    content: "";
                    width: 34px;
                    height: 34px;
                    box-shadow: 0 12px 12px 0 rgb(0 0 0 / 7%);
                    border: solid 1px #ffffff;
                    background-image: linear-gradient(to left, #cd1526, #990715);
                    position: absolute;
                    left: -2px;
                    top: 2px;
                    border-radius: 50%;

                    @include media-breakpoint-up(md){
                        width: 38px;
                        height: 38px;
                        top: 9px;
                        right: 0;
                        margin: 0 auto;
                    }
                }

            }

            a {
                display: block;
                color: white;
                position: relative;
                width: 100%;
                height: 100%;
                line-height: 38px;

                @include media-breakpoint-up(md) {
                    line-height: 57px;
                    font-size: 20px;
                }
            }
        }

        &.description-wrap {
            padding-left: 3%;
            padding-right: 3%;
            margin-top: 10px;

            @include media-breakpoint-up(md) {
                padding-left: 13%;
                padding-right: 13%;
            }

            li {
                font-size: 14px;
                width: 33.33%;

                @include media-breakpoint-up(md) {
                    font-size: 15px;
                }

                span {
                    display: block;
                    width: 70px;

                    &.central {
                        margin: 0 auto;
                        width: 64px;
                        text-align: left;
                    }
                }
            }
        }
    }

    .send-survey {
        margin: 20px auto 20px;
    }
}
//.main-survey-wrap
