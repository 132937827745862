section {

    &.not-found {
        font-family: 'Nunito Sans', sans-serif;
        overflow: hidden;

        .container {
            @include media-breakpoint-up(sm) {
                max-width: 590px;
            }
            @include media-breakpoint-up(md) {
                max-width: 720px;
            }
        }

        .space-content {
            padding-top: 25px;
            padding-left: 0;
            padding-right: 0;
            position: relative;
        }
        .space-content-bottom {
            padding-bottom: 75px;

            @include media-breakpoint-up(sm) {
                padding-bottom: 120px;
            }
        }

        label {
            font-weight: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #191919;

            &.error {
                font-size: em(14px);
                margin-left: 27px;
                margin-top: 16px;

                @include media-breakpoint-up(sm) {
                    position: relative;
                    left: 95px;
                    top: 35px;
                    margin: 0;
                }
            }

            &.page-not-found {
                font-size: em(30px);
                font-weight: 200;
                text-align: center;

                &.es {
                    font-size: em(27px);
                }

                @include media-breakpoint-up(sm) {
                    /*
                    text-align: left;
                    margin-left: 75px;
                     */
                    font-size: em(50px);
                    //width: 490px;

                    &.es {
                        font-size: em(37px);
                    }
                }
                @include media-breakpoint-up(lg) {
                    &.es {
                        font-size: em(37px);
                    }
                }
            }
        }

        .wrap-404 {
            left: 0;
            position: relative;
            height: 190px;
            top: 0;
            margin: 0 auto;
            width: 100%;

            @media (min-width: 374px) {
                width: 300px;
            }

            @include media-breakpoint-up(sm) {
                height: 415px;
                width: 100%;
            }
            @include media-breakpoint-up(md) {
                width: 90%;
            }

            .n4 {
                font-size: em(150px);
                font-weight: 200;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ed1b2f;
                position: absolute;

                @include media-breakpoint-up(sm) {
                    font-size: em(300px);
                }

                &.left {
                    left: 0;
                }
                &.right {
                    right: 0;
                }
            }

            img {
                position: absolute;
                z-index: -1;
                top: 38px;
                margin: auto;
                left: 0;
                right: 0;
                width: 128px;

                @include media-breakpoint-up(sm) {
                    width: 274px;
                    right: 0;
                    top: 55px;
                }
                @include media-breakpoint-up(md) {
                    width: 285px;
                    right: -4px;
                }
            }
        }

        span {

            &.disclaimer {
                color: #848484;
                font-size: em(14px);
                font-weight: normal;
                line-height: normal;
                margin-bottom: 17px;
                margin-top: 10px;
                text-align: center;

                @include media-breakpoint-up(sm) {
                    margin: 10px auto 17px auto;
                    width: 220px;
                }
            }
        }

        .goHome {
            @include media-breakpoint-up(sm) {
                //float: left;
                //margin-left: 75px;
            }
        }

    }
}


