.HomeBannerBox {
    .item {
        position: relative;

        .container {
            position: absolute;
            z-index: 1;
            bottom: 70px;
            padding: 0;
            line-height: normal;

            @include media-breakpoint-up(md) {
                //@include centrar;
                /*
                top: 90%;
                left: 20%;
                transform: translate(-20%, -90%);
                -ms-transform: translate(-20%, -90%);
                bottom: unset;
                max-width: 335px;
                 */
                left: 9%;
                transform: translateX(-15%);
                -ms-transform: translateX(-15%);
                bottom: 0;
                width: 200px;
                height: 100%;
            }
            @include media-breakpoint-up(lg) {
                left: 10%;
                width: 300px;
            }
        }
    }

    .slick-slide {
        .BannerCaption {
            color: white;
            position: relative;
            text-align: center;
            width: 100%;

            @include media-breakpoint-up(md) {
                text-align: left;
                bottom: 0;
                top: 0;
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: end;
                padding-bottom: 20%;
                left: 0;
                width: 100%;
            }

            @media (min-width: 1600px) {
                padding-bottom: 30%;
            }

            img {
                display: none;
                position: absolute;
                top: 15%;
                left: 0;
                width: 100%;
                transform: translateY(0);
                transition: all 500ms ease;

                @media (min-width: 1400px) {
                    top: 20%;
                }

                &.reward {
                    top: 42%;
                    transform: translateY(0);
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    width: 60px;

                    @include media-breakpoint-up(lg) {
                        top: 40%;
                        width: 80px;
                    }
                    @media (min-width: 1400px) {
                        top: 53%;
                        width: 103px;
                    }
                }

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            .wrap-bottom {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: center;

                label {
                    transition: all 500ms ease;
                    padding-left: 15px;
                    padding-right: 15px;
                    height: 35px;
                    line-height: 17px;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 200;
                    width: 130px;
                    min-width: 130px;
                    display: none;

                    &.brv {
                        color: black;
                    }

                    @include media-breakpoint-up(md) {
                        display: block;
                        padding-left: 0;
                        padding-right: 0;
                        opacity: 0;
                        height: 40px;
                        width: 170px;
                    }

                    @include media-breakpoint-up(lg) {
                        transform: translateY(30px);
                        font-size: 18px;
                    }
                }

                > span,
                a {
                    opacity: 0;
                    transition: all 500ms ease;
                    padding-left: 15px;
                    padding-right: 15px;
                    height: 35px;
                    line-height: 16px;
                    font-size: 14px;
                    margin: 0 auto;
                    width: 170px;
                    min-width: 170px;

                    @include media-breakpoint-up(md) {
                        padding-left: 0;
                        padding-right: 0;
                        opacity: 0;
                        margin: 0;
                        height: 40px;
                        width: 170px;
                    }

                    @include media-breakpoint-up(lg) {
                        transform: translateY(30px);
                    }
                }

                p {
                    text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
                    transition: all 500ms ease;
                    font-size: 15px;
                    width: 80%;
                    margin: 0 auto 15px;

                    @include media-breakpoint-up(md) {
                        text-shadow: none;
                        font-size: 15px;
                        margin: 0 0 20px;
                        font-weight: 400;
                        width: 100%;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 20px;
                    }
                }
            }

        }

        &.slick-active {
            .BannerCaption {

                > span,
                label,
                p,
                a {
                    opacity: 1;

                    @include media-breakpoint-up(lg) {
                        transform: translateY(0);
                    }

                    @include media-breakpoint-up(xl) {
                        transform: translateY(-50px);
                    }
                }

                img{
                    opacity: 1;

                    @include media-breakpoint-up(md) {
                        transform: translateY(-15%);
                    }
                    @media (min-width: 1400px) {
                        transform: translateY(-20%);
                    }

                    &.reward {
                        @include media-breakpoint-up(md) {
                            transform: translateY(-42%);
                        }
                        @include media-breakpoint-up(lg) {
                            transform: translateY(-40%);
                        }
                        @media (min-width: 1400px) {
                            transform: translateY(-53%);
                        }
                    }
                }

                > span {
                    transition-delay: 500ms;
                }

                label {
                    transition-delay: 500ms;
                }

                p {
                    transition-delay: 750ms;
                }

                a {
                    transition-delay: 1s;
                }

                img {
                    transition-delay: 1s;
                }
            }
        }
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }

}

.home-slider-main-wrap {
    .slick-dots {
        justify-content: center;

        li {
            button {
                border: 2px solid white;
                border-radius: 50%;
                height: 14px;
                opacity: 0.5;
                width: 15px;

                &:before {
                    color: #000;
                    font-size: 13px;
                    opacity: 0.5;
                    line-height: 16px;
                    height: 14px;
                    width: 15px;
                }
            }

            &.slick-active {

                button {
                    border: 2px solid $brand-primary;
                    border-radius: 50%;
                    opacity: 1;

                    &:before {
                        color: white;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.slick-dots {
    display: flex;
    justify-content: end;
    bottom: 20px;
    left: 0;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    @include media-breakpoint-up(lg) {
        bottom: 30px;
    }

    @include media-breakpoint-up(xl) {
        bottom: 50px;
    }

    li {
        button {
            &:before {
                color: #000;
                font-size: 14px;
                opacity: 0.5;
            }
        }

        &.slick-active button:before {
            color: white;
            opacity: 1;
        }
    }
}

//our vehicles
.OurVehicles {
    @include gradient(#ffffff, #f2f2f2);
    padding: 50px 0;

    @include media-breakpoint-up(md) {
        padding: 70px 0;
    }

    @include media-breakpoint-down(sm) {
        .slick-list {
            padding: 0 15% 0 0;
        }
    }

    .OurVehicles-bar a {
        padding-bottom: 5px;
    }

    &.highlights {
        .VehicleItem {
            position: relative;
            min-width: 285px;

            .VehicleItem-box {
                background-size: auto 100%;
                background-position: center top;
                background-repeat: no-repeat;
                border-radius: 10px;
                padding: 22px;
                height: 320px;
                max-width: 350px;
                width: 100%;

                @include media-breakpoint-up(md) {
                    background-size: cover;
                    background-position: center 0;
                    height: 360px;
                }

                @include media-breakpoint-up(xl) {
                    background-position: center center;
                    height: 360px;
                    width: 359px;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        .vehicles-menu {
                            &.wrap-buttons {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }

                &.lazyloaded {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                }

                .Vehicle-slogan {
                    color: white;
                    font-size: 19px;
                    display: block;
                    text-align: left;
                    margin-bottom: 10px;
                    height: 43px;

                    @include media-breakpoint-up(md) {
                        font-size: 22px;
                        height: 50px;
                        margin-bottom: 0;
                    }
                }

                .Vehicle-model-year {
                    color: white;
                    font-size: 14px;
                    display: block;
                    text-align: left;

                    @include media-breakpoint-up(lg) {
                        font-size: 14px;
                    }
                }

                .Vehicle-name {
                    justify-content: left;
                    font-weight: 400;
                    font-size: 24px;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                    @include media-breakpoint-up(md) {
                        font-size: 22px;
                    }

                    span {
                        color: white;
                        margin-left: 0;
                        font-weight: 400;
                    }
                }

                .Vehicle-price {
                    color: white;
                    text-align: right;
                    font-weight: 700;
                    font-size: 14px;
                    margin-top: -41px;
                    display: flex;
                    flex-direction: column;

                    @include media-breakpoint-up(md) {
                        font-size: 14px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 14px;
                    }

                    span {
                        color: white;
                        font-size: 21px;

                        @include media-breakpoint-up(xl) {
                            font-size: 23px;
                        }

                        small {
                            color: white;
                        }
                    }
                }

                .vehicles-menu {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;

                    &.wrap-buttons {
                        margin-top: 0;
                        margin-bottom: 160px;

                        @include media-breakpoint-up(md) {
                            margin-top: 15px;
                            margin-bottom: 180px;
                        }
                        @include media-breakpoint-up(lg) {
                            margin-top: 10px;
                            //margin-bottom: 127px;
                            margin-bottom: 170px;
                            opacity: 0;
                            visibility: hidden;
                        }
                        @include media-breakpoint-up(xl) {
                            //margin-bottom: 155px;
                        }

                        .BtnStd {
                            background-color: $brand-primary;
                            border: 0;
                            height: 30px;
                            padding: 10px;
                            line-height: 11px;
                            margin-top: 0;
                            width: 100px;
                            min-width: inherit;

                            @include media-breakpoint-up(md) {
                                width: 114px;
                            }
                            @include media-breakpoint-up(lg) {
                                background-color: rgba(0,0,0,0.2);
                                height: 38px;
                                padding: 10px;
                                line-height: 18px;
                                max-width: 147px;
                                width: 147px;
                            }

                            &:hover {
                                background-color: $brand-primary;
                                border: 0;
                            }
                        }
                    }

                }
            }
        }

    }
}

.OurVehicles-bar {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    position: relative;

    a {
        margin: 0 10px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: em(12px);
        font-weight: 700;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            margin: 0 25px;
            font-size: em(14px);
        }

        @include media-breakpoint-up(lg) {
            br {
                display: none;
            }
        }
    }
}

//
.hBuilds {
    .BuilElemMain {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.BuilElem-box {
    background-color: #ececec;
}

// certs video guides
.CertVideosGuides-box {
    position: relative;
    overflow: hidden;

    .CertVideosGuides {
        padding-top: 70px;
        padding-bottom: 70px;

        &.pre-owned {

            h3 {
                width: 50%;

                @include media-breakpoint-up(lg) {
                    width: 100%;
                }
            }

        }

        h3 {
            font-size: em(30px);
            font-weight: 200;
            line-break: auto;

            @include media-breakpoint-up(lg) {
                font-size: em(40px);
            }

            @include media-breakpoint-up(xl) {
                font-size: em(50px);
            }

            @media #{$mq-2k} {
                font-size: em(45px);
            }
        }

        p {
            color: $aux-gray3;
            font-size: em(14px);
            margin-bottom: 15px;
            padding-right: 140px;

            br {
                display: none;
            }

            @include media-breakpoint-up(md) {
                padding-right: 49%;
            }

            @include media-breakpoint-up(xl) {
                padding-right: 0;

                br {
                    display: inline-block;
                }
            }
        }
    }

    .Certs {
        @include media-breakpoint-down(md) {
            background: url("/images/certified-img-xs.webp") no-repeat right center;
        }

        @include media-breakpoint-up(lg) {
            background: url("/images/certified-img.webp") no-repeat right center;
        }

        p {
            @include media-breakpoint-down(sm) {
                width: 50%;
                padding-right: 15px;
            }
        }
    }

    .Videos {
        background-color: $aux-gray1;

        @include media-breakpoint-up(lg) {
            padding-left: 55px;
        }

        &::after {
            background-color: $aux-gray1;
            content: "";
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 100%;
        }
    }

    .VideoImg {
        position: absolute;
        right: 0;
        top: 120px;
        padding: 10px;

        @include media-breakpoint-down(md) {
            width: 41.33333333%;
        }

        figure {
            background-color: #fff;
            box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.24);
            cursor: pointer;
            display: inline-block;
            padding: 5px;
            position: relative;

            svg {
                position: absolute;
                @include centrar;

                @include media-breakpoint-down(sm) {
                    width: 35px;
                    height: 35px;
                }

                circle {
                    stroke: #FFF;
                    transition: stroke 500ms ease;
                }

                path {
                    fill: #FFF;
                    transition: fill 500ms ease;
                }
            }

            @include hover {
                svg {
                    circle {
                        stroke: $brand-primary;
                    }

                    path {
                        fill: $brand-primary;
                    }
                }
            }
        }

        span {
            display: block;
            text-align: center;
            margin-bottom: 10px;
            width: 130px;
            margin: 0 auto;
        }
    }
}

//newsletter

.NewsletterBox {
    @include media-breakpoint-down(sm) {
        background: url('/images/bg-newsletter-xs.webp') #ececec no-repeat right center;
        background-size: contain;
    }

    @include media-breakpoint-up(md) {
        background: url('/images/bg-newsletter.webp') #ececec no-repeat center center;
        background-size: cover;
    }

    .Newsletter-form {
        color: $brand-third;
        padding: 40px 0;

        @include media-breakpoint-up(md) {
            padding: 60px 0;
        }
    }

    h2 {
        font-size: 40px;
        font-weight: 200;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
        }
    }

    p {
        font-size: 14px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }

    .NewsletterGroup {
        position: relative;
    }

    input[type=email] {
        background-color: #ececec;
        border: 1px solid $brand-primary;
        border-radius: 20px;
        box-shadow: none;
        color: #4f4f4f;
        font-size: 14px;
    }

    input[type=submit] {
        background: url('/images/arrow-left-w.svg') $brand-primary no-repeat center center;
        border: none;
        border-radius: 50px;
        height: 45px;
        width: 45px;
        position: absolute;
        top: 0;
        right: -5px;
        transition: width 250ms ease;

        &:hover {
            width: 70px;
        }
    }
}

//Recall
.Recall-box {
    background: #191919;

    @include media-breakpoint-up(md) {
        background: url('/images/recall-bg@3x.webp') #191919 no-repeat center center;
        background-size: cover;
    }

    color: #ffffff;
    position: relative;

    @include media-breakpoint-only(lg) {
        background-size: cover;
    }

    .Recall-flex {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    .RecallText {
        padding: 25px 0;

        @include media-breakpoint-up(md) {
            padding: 0 0 0 20px;
            width: 74.333333%;
        }

        span {
            font-size: em(14px);
        }

        h3 {
            font-size: em(40px);
            font-weight: 200;

            @include media-breakpoint-up(md) {
                font-size: em(30px);
            }

            @include media-breakpoint-up(lg) {
                font-size: em(40px);
            }

            @include media-breakpoint-up(xl) {
                font-size: em(50px);
            }
        }
    }

    .Recall-action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;

        @include media-breakpoint-down(sm) {
            background: url('/images/recall-bg-xs.webp') no-repeat center center;
            padding-top: 35px;
            padding-bottom: 25px;
        }

        p {
            padding-top: 10px;
        }
    }
}

.Recall-data {
    padding: 30px 15px;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
        padding: 70px 15px;
    }
}

.RecallSymbol {
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.5), inset -1px -5px 25px 4px rgba(0, 0, 0, 0.26);
    width: 128px;
    height: 128px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.Recall-symbol {
    @include media-breakpoint-up(md) {
        width: 128px;
    }

    figure {
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }
}

.wrap-continue-exploring {
    padding: 50px 0;

    @include media-breakpoint-up(md) {
        padding: 70px 0;
    }

    .exploring-slider {

        .item {
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;

            .main-wrap {
                background-color: #f2f2f2;
                border-radius: 15px;
                padding: 15px;
                transition: all 500ms ease;

                @include media-breakpoint-up(lg) {
                    padding: 25px;
                }

                &.active, &:hover {
                    background-color: white;
                    box-shadow: 1.4px 2.1px 5.3px rgba(0, 0, 0, 0.028),
                    4.7px 6.9px 9.9px rgba(0, 0, 0, 0.042),
                    0px 6px 30px rgba(0, 0, 0, 0.07);
                }

                .info {
                    margin: 0 auto;
                    width: 95%;

                    .title {
                        display: block;
                        color: $brand-primary;
                        font-size: 30px;
                        margin-bottom: 5px;
                        text-align: left;
                    }

                    .description {
                        color: $brand-secondary;
                        font-size: 18px;
                        display: block;
                        text-align: left;
                        margin-bottom: 20px;
                        line-height: 20px;
                        min-height: 20px;
                        height: 60px;

                        @include media-breakpoint-up(md) {
                            height: 38px;
                        }

                    }

                    .wrap-buttons-img {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        margin: 0 auto;
                        flex-direction: column;
                        width: 100%;

                        @include media-breakpoint-up(lg) {
                            flex-direction: row;
                        }

                        .BtnStd {
                            background-color: transparent;
                            border: 1px solid $brand-primary;
                            height: 33px;
                            line-height: 0;
                            margin-bottom: 15px;
                            width: 190px;

                            &.active, &:hover {
                                background-color: $brand-primary;
                                border: 1px solid $brand-primary;
                                color: white;
                            }
                        }

                        .img-build {
                            background-position: 0 bottom;
                            background-repeat: no-repeat;
                            background-size: 100% auto;
                            height: 100px;
                            width: 200px;

                            @include media-breakpoint-up(md) {
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: 100% auto;
                                height: 118px;
                                width: 300px;
                            }
                        }

                        img {
                            margin-top: 30px;
                        }

                    }
                }

            }

        }

        .slick-arrow {
            background-color: $brand-primary;
            color: white;
            height: 38px;
            width: 38px;
            border-radius: 50%;

            &:before {
                opacity: 1;
                padding-top: 3px;
                line-height: 0;
                display: block;
            }

            &.slick-next {
                right: -10px;
            }

            &.slick-prev {
                left: -10px;
                z-index: 1;
            }
        }
    }
}

.MenuVehicles_types {
    &.home {

        @include media-breakpoint-up(xl) {
            /* the slides */
            .slick-slide {
                margin: 0 10px;
            }
            /* the parent */
            .slick-list {
                margin: 0 -10px;
            }
        }

        .slick-prev, .slick-next {
            z-index: 1;
            height: 35px;
            width: 35px;
            background-color: white;
            border-radius: 50%;

            &:before {
                color: $brand-primary;
                font-size: 39px;
                left: -2px;
                display: block;
                opacity: 1;
                position: relative;
                top: 1px;
            }
        }

    }
}
