.MenuTop {

    //hidde on mb
    @include media-breakpoint-down(md) {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        display: block;
        flex-grow: 1;
        padding-left: 30px;
    }

    nav {

        @include media-breakpoint-up(lg) {
            height: 100%;
            font-size: em(13px);
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        ul {
            @include media-breakpoint-up(lg) {
                display: flex;
                width: inherit;
            }

            >li {
                border-bottom: solid 1px #e0e0e0;
                list-style-type: none;

                @include media-breakpoint-up(lg) {
                    background-color: #fff;
                    border-bottom: none;
                    padding: 0 15px;
                    transition: all 500ms ease;
                }

                &.gotoVehiclesMenu {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &.gotoShopping {
                    @include media-breakpoint-up(lg) {
                        margin-right: auto;
                    }
                }

                &.short-menu {
                    position: relative;
                }

                .tech-nav {
                    /*
                    img {
                        margin-right: 5px;
                        width: 115px;
                    }
                     */
                }

                .SocialLinks {
                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }

                >a,
                >div {
                    color: $brand-third;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 22px 8.33333333%;


                    @include media-breakpoint-up(lg) {
                        height: 100%;
                        transition: all 500ms ease;
                        padding: 0;

                        i {
                            margin-left: 5px;
                        }
                    }
                }

                &:hover {
                    background: $aux-gray1;

                    >a,
                    >div {
                        color: $brand-primary;
                    }
                }

                #whatsapp-button {
                    display: none;
                    position: absolute;
                    border-radius: 100%;
                    font-size: 22px;
                    height: 45px;
                    width: 45px;
                    justify-content: center;
                    align-items: center;
                    margin: 0 8px;
                    border: solid 1px #32d950;
                    color: #32d950;
                    top: 13px;
                    left: -55px;

                    @include media-breakpoint-up(xl) {
                        display: flex;
                    }

                    i {
                        margin: 0 !important;
                    }
                }

            }
        }
    }
}

.MenuMB {
    .dropMenus {
        opacity: 0;
        visibility: hidden;
        transition: all 500ms ease;
    }

    li {
        &.current {
            .dropMenus {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.MenuTop {
    @include media-breakpoint-up(lg) {
        .dropMenus {
            opacity: 0;
            visibility: hidden;
            transition: all 500ms ease;
        }
    }

    li {
        &.current {
            .dropMenus {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

//Mobile
.Hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    width: 40px;

    span {
        background-color: $brand-third;
        display: block;
        width: 30px;
        height: 2px;
        margin: 3px auto;
        transition: all 500ms ease;
    }

    &.openMenu {
        span {
            &:nth-child(1) {
                transform: translate(0px, 8px) rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: translate(0px, -8px) rotate(-45deg);
            }
        }
    }
}

.MenuOnMB {

    display: none;

    &.openMenuTop {
        display: block;
    }

    @include media-breakpoint-down(md) {
        background-color: #f6f6f6;
        position: fixed;
        top: 71px;
        left: 0;
        right: 0;
        height: calc(100vh - 56px);
        overflow: auto;

        a:not(.OpenCarPlease) {
            i {
                &::before {
                    //content: "";
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        top: 56px;
    }

    nav {
        ul {
            >li {
                border-bottom: solid 1px #e0e0e0;
                list-style-type: none;

                &.gotoVehiclesMenu {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                >a,
                >div {
                    color: $brand-third;
                    display: flex;
                    align-items: center;
                    padding: 22px 8.33333333%;

                    @include media-breakpoint-down(md) {
                        &:not(.tech-nav) {
                            justify-content: space-between;
                        }
                    }
                }

                &:hover {
                    background: $aux-gray1;

                    >a,
                    >div {
                        color: $brand-primary;
                    }
                }

                .SocialLinks {
                    display: flex;
                    justify-content: center !important;

                    @include media-breakpoint-down(sm) {
                        padding: 20px 0;
                    }

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }

                    a {
                        border-radius: 100%;
                        font-size: 22px;
                        height: 45px;
                        width: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 8px;

                        &.wa {
                            border: solid 1px #32d950;
                            color: #32d950;
                        }

                        &.fb {
                            border: solid 1px #0053dc;
                            color: #0053dc;
                        }

                        &.in {
                            border: solid 1px #f67d12;

                            i {
                                background: linear-gradient(46deg, #f67d12, #f41a8b);
                                //background-clip: text;
                                //-webkit-text-fill-color: transparent;
                                //text-fill-color: transparent;
                            }

                            img {
                                width: 24px;
                            }
                        }

                        &.twt {
                            border: solid 1px #27caff;
                            color: #27caff;
                        }

                        &.yt {
                            border: solid 1px #f42929;
                            color: #f42929;
                        }

                        i {
                            height: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
.techtutor {
    width: 100px;
}
