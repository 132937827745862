.ServicesMenu {
    background-color: #eee;
    font-size: 18px;
    font-weight: 200;
    position: relative;
    flex-direction: column;
    display: flex;

    &.guatemala {
        .icon-express-service {
            order: 1;
        }
        .icon-online-appointment {
            order: 2;
        }
        .icon-know-honda {
            order: 3;
        }
        .icon-parts-request {
            order: 4;
        }
        .iicon-service {
            order: 5;
        }
        .icon-tool {
            order: 6;
        }
        .icon-offers {
            order: 7;
        }
    }

    @include media-breakpoint-only(md) {
        padding-left: 34px;
    }

    @include media-breakpoint-up(lg) {
        width: 250px;
        position: absolute;
        top: 100%;
        left: -153px
    }

    &.PartsGenuineMenu {

        @include media-breakpoint-up(lg) {
            left: -98px;
        }

    }

    .wrap-submenu {

        &:hover, &:active {
            .submenu {
                display: block;
            }
        }

        .submenu {
            background-color: #eee;
            position: absolute;
            right: -240px;
            top: 0;
            display: none;
            width: 240px;

            .menu-icon {
                background-position: 30px center;
                background-repeat: no-repeat;
                border-bottom: 1px solid #e4e4e4;
                color: $brand-third;
                display: block;
                padding: 15px 60px;
                transition: color 500ms ease;
                float: left;
                width: 100%;

                &:hover, &:active, &.active {
                    color: $brand-primary;
                }
            }
        }
    }

}

.menu-icon {
    background-position: 30px center;
    background-repeat: no-repeat;
    border-bottom: 1px solid #e4e4e4;
    color: $brand-third;
    display: block;
    padding: 15px 60px;
    position: relative;
    transition: color 500ms ease;

    &:hover {
        color: $brand-primary;
    }

    .fa {
        position: absolute;
        right: 95px;
        top: 20px;
    }

    &.icon-service {
        background-image: url('/images/icon-service.svg');
    }
    &.icon-express-service {
        background-image: url('/images/ico-express-service.svg');
        background-position: 20px center;
    }
    &.icon-parts-request {
        background-image: url('/images/icon-parts-request.svg');
        background-position: 29px center;
        background-size: 22px;
    }
    &.icon-paint {
        background-image: url('/images/ico-paint.svg');
    }

    &.icon-tool {
        background-image: url('/images/icon-tool.svg');
    }

    &.icon-recall {
        background-image: url('/images/icon-danger.svg');
    }

    &.icon-location {
        background-image: url('/images/icon-location-small.svg');
    }

    &.icon-about {
        background-image: url('/images/icon-small-user.svg');
    }
    &.icon-briefcase {
        background-image: url('/images/icon-small-briefcase.svg');
    }

    &.icon-know-honda {
        background-image: url('/images/vehicle.svg');
        background-size: 30px auto;
        background-position: 21px center;
    }

    &.icon-offers {
        background-image: url('/images/icon-offer.svg');
        background-size: 19px auto;
    }

    &.icon-online-appointment {
        background-image: url('/images/icon-date-red.svg');
    }

    &.icon-eye {
        background-image: url('/images/icon-eye.svg');
        background-size: 20px auto;
    }
    &.icon-email {
        background-image: url('/images/icon-email.svg');
        background-size: 20px auto;
    }
}
