:lang(es) {
    header {
        .MenuMB {
            ul {
                li {
                    &:nth-child(2) {
                        flex-grow: initial;
                    }
                }
            }
        }
    }

    .VehicleOwnMenu-links {
        ul {
            li {
                @include media-breakpoint-up(xl) {
                    margin: 0 10px;
                }
            }
        }
    }

    .BuildElem .BuildElem-inner h3 {
        @include media-breakpoint-down(sm) {
            font-size: em(19px);
        }
    }

    .VehicleOwnMenu .VehicleOwnMenu-active span {
        font-size: em(12px);
    }

    @media (min-width:1024px) and (max-width:1040px) {
        .VehicleOwnMenu .VehicleOwnMenu-links ul {
            display: flex;
            justify-content: space-between;

            li {
                margin: 0;
            }
        }
    }

    .OurVehicles-bar a {
        @include media-breakpoint-down(sm) {
            margin: 0 8px;
        }
    }

    .SocialFt {

        >.row {
            >div:first-child {
                padding-right: 0;
            }
        }
    }
}
