/**
* Media query for non touch screens
**/

@mixin screen-no-touch {
    @media (min-width: 1025px) {
        @content;
    }
}

/**
* Placeholder
*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    ;

    &:-moz-placeholder {
        @content
    }

    ;

    &::-moz-placeholder {
        @content
    }

    ;

    &:-ms-input-placeholder {
        @content
    }

    ;
}


/**
* Hover
*/

@mixin hover {
    @include screen-no-touch {

        &:hover,
        &.current {
            @content;
        }
    }

    &.active {
        @content;
    }
}

/*
 * Centrar | parametros : vertical - horizontal - vacio centra ambos;
 */

@mixin centrar($direccion:"") {
    @if $direccion==vertical {
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
    }

    @else if $direccion==horizontal {
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
    }

    @else {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
}

/*
Gradients
*/
@mixin gradient($orientation:"vertical", $start:"", $end:"") {
    background: $start;
    position: relative;
    overflow: hidden;

    @if $orientation=="horizontal" {
        background: -moz-linear-gradient(left, $start 0%, $end 100%);
        background: -webkit-linear-gradient(left, $start 0%, $end 100%);
        background: linear-gradient(to right, $start 0%, $end 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$end', GradientType=1);
        z-index: 1;
    }

    @else if $orientation=="vertical" {
        background: -moz-linear-gradient(top, $start 0%, $end 100%);
        background: -webkit-linear-gradient(top, $start 0%, $end 100%);
        background: linear-gradient(to bottom, $start 0%, $end 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$end', GradientType=0);
        z-index: 1;
    }

    @else if $orientation=="diagonal-top" {
        background: -moz-linear-gradient(45deg, $start 0%, end 100%);
        background: -webkit-linear-gradient(45deg, $start 0%, end 100%);
        background: linear-gradient(45deg, $start 0%, end 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='end', GradientType=1);
    }

    @else if $orientation=="diagonal-bottom" {
        background: -moz-linear-gradient(-45deg, $start 0%, $end 100%);
        background: -webkit-linear-gradient(-45deg, $start 0%, $end 100%);
        background: linear-gradient(135deg, $start 0%, $end 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$end', GradientType=1);
    }

    @else if $orientation=="radial" {
        background: -moz-radial-gradient(center, ellipse cover, $start 0%, $end 100%);
        background: -webkit-radial-gradient(center, ellipse cover, $start 0%, $end 100%);
        background: radial-gradient(ellipse at center, $start 0%, $end 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$end', GradientType=1);
    }

}

//underline menu
// Mixin hover underline to center
@mixin ui-underlineCenter($underHeight:1px, $color:white, $pd-bottom:null) {
    cursor: pointer;
    position: relative;
    padding-bottom: $pd-bottom;

    &:before {
        content: "";
        position: absolute;
        left: 51%;
        right: 51%;
        bottom: 0;
        background: $color;
        height: $underHeight;
        transition-property: left, right;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
    }

    //active state
    @media (min-width: 1024px) {
        &.active:before {
            left: 0;
            right: 0;
        }
    }

    // only for desktop
    @media (min-width: 1025px) {
        &:hover:before {
            left: 0;
            right: 0;
        }
    }
}
