.DealerBox {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
    }

    .Blog {
        @include media-breakpoint-down(md) {
            background: url("/images/blog-header-xs.jpg") no-repeat center top;
            background-size: cover;
        }


        @include media-breakpoint-up(lg) {
            background: url("/images/blog-header.jpg") no-repeat center top;
            background-size: cover;
        }
    }
}

.mainBlog {

    .wrapBlogSearch {
        .blog-description {
            display: block;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #848484;
            margin: 0 auto;
            max-width: 625px;
        }

        .filterForm {
            display: flex;
            margin-bottom: 80px;

            @include media-breakpoint-up(md) {
                margin-bottom: 105px;
            }

            select {
                background-image: url(/images/ico-arrow-down-red.svg);
                background-size: 16px auto;
                background-repeat: no-repeat;
                background-position: 86% center;
                color: $brand-primary;
                -ms-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                font-size: 14px;
                font-weight: 300;
                text-align: left;
                width: 177px;
                height: 44px;
                border-radius: 22px;
                border: solid 1px $brand-primary;
                position: relative;
                padding-left: 30px;
            }

            input {
                border-radius: 22px;
                border: solid 1px $brand-primary;
                box-shadow: none;
                margin-left: 30px;
            }

            button {
                background-image: url(/images/icon-search.svg);
                background-size: 16px auto;
                background-repeat: no-repeat;
                background-position: 75% center;
                width: 180px;
                height: 45px;
                border-radius: 22px;
                background-color: $brand-primary;
                border: 0;
                display: block;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                color: #fff;
                margin-left: -35px;
                text-align: left;
                padding-left: 30px;
            }
        }
    }

    .list {
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }

        .wrap-info {
            margin: 0 auto;
            width: 90%;

            h2 {
                font-size: 18px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                margin-bottom: 15px;
                margin-top: 20px;
                text-align: left;
                color: $brand-primary;
                font-weight: 700;

                @include media-breakpoint-up(md) {
                    min-height: 50px;
                }
            }
            .description {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #848484;
            }
            .wrapFooter {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .date-created {
                    font-size: 14px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $brand-secondary;
                }
                .author {
                    font-size: 14px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $brand-secondary;
                }

            }

        }

        .not-found {
            color: #848484;
            text-align: center;
            display: block;
            font-size: 14px;
        }
    }

    .blogPagination {
        padding-bottom: 40px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 140px;
        }

        .pagination {
            align-items: center;
            justify-content: center;

            .page-item {
                margin-left: 2px;
                margin-right: 2px;

                &.disabled {
                    &:first-child, &:last-child {
                        span, a {
                            opacity: 0.5;
                        }
                    }
                }

                &:first-child {
                    span, a {
                        background-color: #343434;
                        background-image: url('/images/ico-arrow-white.svg');
                        background-repeat: no-repeat;
                        background-size: 10px auto;
                        background-position: center center;
                        border-radius: 0;
                        color: transparent;
                        width: 55px;
                    }
                }
                &:last-child {
                    span, a {
                        background-color: #343434;
                        background-image: url('/images/ico-arrow-white.svg');
                        background-repeat: no-repeat;
                        background-size: 10px auto;
                        background-position: center center;
                        border-radius: 0;
                        color: transparent;
                        width: 55px;
                        transform: rotate(180deg);
                    }
                }

                .page-link {
                    background-color: #ebebeb;
                    color: #7f7f7f;
                }

                &.active {
                    .page-link {
                        border-color: $brand-primary;
                        background-color: $brand-primary;
                        color: white;
                    }
                }
            }
        }
    }

    .head {
        margin-bottom: 50px;
        margin-top: 20px;

        .goBack {
            background-image: url(/images/arrow-red.webp);
            background-position: 20px center;
            background-size: 10px auto;
            background-repeat: no-repeat;
            width: 153px;
            height: 44px;
            padding: 13px 32px 13px 35px;
            border-radius: 22px;
            border: solid 1px $brand-primary;
            position: absolute;
            top: 35px;
            left: -70px;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            display: block;
            color: $brand-primary;
            text-transform: uppercase;
        }

        label {
            display: block;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-top: 15px;
            color: #4f4f4f;

            &.category {
                margin-top: 90px;
            }

            &.author {
                color: $brand-primary;
            }
        }
        h1 {
            font-size: 30px;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.96;
            letter-spacing: normal;
            text-align: center;
            color: $brand-primary;
            margin: 10px auto 0;
            width: 300px;

            @include media-breakpoint-up(md) {
                font-size: 50px;
                width: 640px;
            }
        }
    }

    .blogBanner {
        position: relative;

        picture {
            position: relative;
            z-index: 1;
        }
        .redBlock {
            background-color: $brand-primary;
            height: 238px;
            width: 100%;
            position: absolute;
            bottom: -22%;
            left: 0;
            z-index: 0;

            @include media-breakpoint-up(md) {
                bottom: 0;
            }
        }
    }

    .wrapBlogContent {
        position: relative;

        .wrapSocialMedia {
            display: flex;
            flex-direction: column;
            position: absolute;
            align-items: center;
            left: 15%;
            top: 4%;

            label {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $brand-primary;
                margin-bottom: 25px;
            }

            .socialBtn {
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: 100% auto;
                display: block;
                width: 47px;
                height: 46px;
                margin-bottom: 18px;

                &.facebook {
                    background-image: url("/images/facebook-icon.webp");
                }
                &.instagram {
                    background-image: url("/images/instagram-icon.webp");
                }
                &.twitter {
                    background-image: url("/images/twitter-icon.webp");
                }
                &.whatsapp {
                    background-image: url("/images/whatsapp-icon.webp");
                }
                &.linkedin {
                    background-image: url("/images/linkedin-icon.webp");
                }
            }
        }

        .blogContent {
            color: #848484;
            font-size: 14px;

            h2, h3, h4 {
                display: block;
                font-size: 36px;
                font-weight: 200;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $brand-primary;
                max-width: 708px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }

            p {
                max-width: 708px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }

            img {
                height: auto;
                width: 100%;
            }

            img {
                height: auto;
                width: 100%;
            }

            iframe {
                min-height: 500px;
                height: auto !important;
                width: 100% !important;
            }
        }

    }


    .blogNav {
        .bNavBtn {
            background-color: #e5e5e5;
            padding: 23px;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                &:hover {
                    background-color: #d3d3d3;
                }
            }

            &.nextPost {
                h3 {
                    padding-left: 25px;
                }
                label {
                    padding-left: 25px;
                }
            }

            h3 {
                font-size: 17px;
                font-weight: 200;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: normal;
                padding-top: 15px;
                color: #414141;

                @include media-breakpoint-up(md) {
                    font-size: 26px;
                }
            }
            label {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $brand-primary;
                display: block;

                &.date {
                    margin-bottom: 15px;
                }
                &.prev {
                    background-image: url("/images/arrow-red.webp");
                    background-position: 0 center;
                    background-size: 10px auto;
                    background-repeat: no-repeat;
                    padding-left: 20px;
                }
                &.next {
                    background-image: url("/images/arrow-red-next.webp");
                    background-position: 83px center;
                    background-size: 10px auto;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .blog-title {
        font-size: 36px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $brand-primary;
        margin-top: 60px;

        @include media-breakpoint-up(md) {
            margin-top: 86px;
        }
    }

}
