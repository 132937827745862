.collapseList {

    margin: 0;
    padding: 0;

    li {
        display: block;
        margin-bottom: 2px;

        .collapseHandler {
            border-bottom: 1px solid #d9d9d9;
            color: $brand-third;
            cursor: pointer;
            font-style: normal;
            line-height: normal;
            padding: 15px 15px 15px 0;
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint-up(lg) {
                border-bottom: none;
                cursor: text;
            }
        }

        .dataCollapse {
            padding: 20px 0;
            overflow: hidden;
        }
    }

    &.no-data {
        .collapseHandler {
            cursor: default;
        }
    }
}

.collapseThis {

    em,
    i {
        position: relative;
        height: 15px;
        width: 15px;
        transition: all 0.5s ease;

        &.arrow {

            &::before,
            &::after {
                background-color: $brand-third;
                content: "";
                display: block;
                height: 2px;
                width: 15px;
                position: absolute;
            }

            &::before {
                top: 4px;
                left: 5px;
                transform: rotate(45deg);
            }

            &::after {
                bottom: 4px;
                left: 5px;
                transform: rotate(-45deg);
            }
        }

        &.circle-arrow {
            border: 2px solid white;
            border-radius: 50%;

            &::before,
            &::after {
                background-color: white;
                content: "";
                display: block;
                height: 2px;
                width: 8px;
                position: absolute;
            }

            &::before {
                top: 4px;
                left: 5px;
                transform: rotate(45deg);
            }

            &::after {
                bottom: 4px;
                left: 5px;
                transform: rotate(-45deg);
            }
        }

        &.plus {

            &::before,
            &::after {
                background-color: $brand-third;
                content: "";
                display: block;
                position: absolute;
                @include centrar;
            }

            &:before {
                height: 2px;
                width: 100%;
                opacity: 1;
                transition: opacity 500ms ease;
            }

            &::after {
                height: 100%;
                width: 2px;
            }
        }
    }

    &.active {


        em,
        i {
            &.arrow {
                transform: translateY(-50%) rotate(90deg);
            }

            &.plus {
                transform: rotate(90deg);

                &::before {
                    opacity: 0;
                }
            }
        }

    }
}

.no-touch {
    .collapseList {
        li {

            &:hover {
                span em {
                    transform: translateY(-50%) rotate(90deg);
                }
            }

        }
    }
}
