.Video-box {

    position: fixed;
    width: 100%;
    @include centrar;
    max-width: 1000px;
    z-index: 7;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;

    @media (min-width:1600px) {
        max-width: 1400px;
    }

    @media (max-height:900px) {
        top: 70px;
        transform: translate(-50%,0);
    }

    &.showme,
    &.showme~.OverlayModal,
    &.showme~.video-disclaimer-box {
        opacity: 1;
        visibility: visible;
    }

    .Video-data {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        overflow: hidden;
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .videoLink {
        margin: 10px auto 0;
        position: absolute;
        left: 0;
        right: 0;
        width: 180px;
    }
}

.OverlayModal {
    background: rgba(0, 0, 0, .8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 6;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
}

.video-close {
    color: $brand-primary;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    font-size: 2.5em;
    line-height: 40px;
    text-align: center;
}

.video-disclaimer-box {
    color: white;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    padding: 30px 50px 40px;
    position: fixed;
    text-align: center;
    visibility: hidden;
    width: 85%;
    z-index: 6;
}
