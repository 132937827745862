// Bootstrap
@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/nunito-sans/nunitosans-regular.woff2') format('woff2'),
    url('../fonts/nunito-sans/nunitosans-regular.woff') format('woff'),
    url('../fonts/nunito-sans/nunitosans-regular.ttf') format('truetype');
    font-weight: 400; // Regular
    font-style: normal;
    font-display: swap; // Asegura que el texto sea visible mientras se carga la fuente
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/nunito-sans/nunitosans-bold.woff2') format('woff2'),
    url('../fonts/nunito-sans/nunitosans-bold.woff') format('woff'),
    url('../fonts/nunito-sans/nunitosans-bold.ttf') format('truetype');
    font-weight: 700; // Bold
    font-style: normal;
    font-display: swap; // Asegura que el texto sea visible mientras se carga la fuente
}


@import "plugs/slick.scss";
@import "plugs/slick-theme.scss";

// Settings
@import "functions";
@import "front/settings";
@import "front/mixins";
@import "fontawesome/fontawesome.scss";
@import "fontawesome/solid.scss";
@import "fontawesome/brands.scss";
@import "fontawesome/regular.scss";

//theme
@import "front/ui";
@import "front/layout";
@import "front/layout/header";
@import "front/collapse";
@import "front/animations";
@import "front/videoyt";

//404
@import "404";

//home
@import "front/home/home";

//fixes lang: ES
@import "front/fix-es";

//quality survey
@import "front/layout/quality_survey";

//Extras
@import "front/country/guatemala";

//Teasers
@import "front/teaser/index";

//Blog
@import "front/blog/index";
