header {
    background-color: #fff;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: all 500ms ease;

    @include media-breakpoint-up(lg) {
        padding: 0 27px;
        height: 70px;
    }

    .Brand {
        display: flex;
        align-items: center;
        width: 16.3333333%;
        margin: 10px 20px;

        @include media-breakpoint-up(md) {
            width: 10.66666667%;
        }

        @include media-breakpoint-up(lg) {
            width: 175px;
            margin: 0;
        }

        picture {
            width: 100%;
        }
    }

    .MenuMB {
        @include media-breakpoint-up(lg) {
            display: none;
        }

        flex-grow: 1;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            height: 100%;
            list-style-type: none;

            li {
                &:nth-child(2) {
                    flex-grow: 1;
                }

                &:last-child {
                    margin-left: auto;
                }

                .ServicesMenu {
                    position: fixed;
                    top: 51px;
                    left: 0;
                    width: 100vw;

                    @include media-breakpoint-only(md) {
                        top: 72px;
                    }
                }

            }
        }
    }

    .MbOptions {
        color: $brand-third;
        border: 1px solid #f1f1f1;
        border-top: none;
        border-bottom: none;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @include media-breakpoint-up(md) {
            font-size: 14px;
        }

        figure {
            width: 22px;
            height: auto;
            margin: 0 auto 5px auto;

            @include media-breakpoint-up(md) {
                width: 30px;
            }

            @include media-breakpoint-up(lg) {
                width: 40px;
            }
        }

        span {
            display: block;
        }
    }

    .MbIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
