footer {
    margin-left: -15px;
    margin-right: -15px;

    .FtBarTop {
        background-color: $aux-gray2;
        padding: 30px 0;

        .FtBarTop-social {
            align-items: center;
        }

        .SocialFt {

            >.row {
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .SocialLinks {
            display: flex;

            @include media-breakpoint-down(sm) {
                justify-content: center;
                padding: 20px 0;
            }

            a {
                border-radius: 100%;
                font-size: 22px;
                height: 45px;
                width: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 8px;

                &.wa {
                    border: solid 1px #32d950;
                    color: #32d950;
                }

                &.fb {
                    border: solid 1px #0053dc;
                    color: #0053dc;
                }

                &.in {
                    border: solid 1px #f67d12;

                    i {
                        background: linear-gradient(46deg, #f67d12, #f41a8b);
                        //background-clip: text;
                        //-webkit-text-fill-color: transparent;
                        //text-fill-color: transparent;
                    }

                    img {
                        width: 24px;
                    }
                }

                &.twt {
                    border: solid 1px #27caff;
                    color: #27caff;
                }

                &.yt {
                    border: solid 1px #f42929;
                    color: #f42929;
                }
            }
        }

        .Ft-action {
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: right;
            }
        }
    }

    .SiteMapNav {
        background-color: #e5e5e5;
        padding-top: 50px;
        padding-bottom: 50px;

        .SiteMap {
            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: space-between;
            }


            a {
                display: block;
            }
        }

        .SiteMap-block {
            list-style-type: none;

            span {
                @include media-breakpoint-up(lg) {
                    display: inline-block;
                    color: $brand-third;
                    position: relative;
                    padding-bottom: 5px;

                    &::after {
                        background-color: $brand-primary;
                        content: "";
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }
            }

            .SiteMap-links {
                padding-top: 10px;

                a {
                    color: $brand-alternative;
                    font-size: em(14px);
                    margin-bottom: 5px;
                    transition: color 500ms ease;

                    @include hover {
                        color: $brand-primary;
                    }

                    @media #{$mq-2k} {
                        font-size: em(12px);
                    }
                }
            }
        }
    }

    .FtBarBtm {
        background-color: $brand-third;
        color: #b4b4b4;
        font-size: em(10px);
        text-align: center;
        padding: 30px 0;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        .FtBarBtm-legal {
            margin-bottom: 25px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        .FtBarBtm-copy {
            @include media-breakpoint-up(md) {
                font-size: em(12px);
            }

            @media #{$mq-2k} {
                font-size: em(18px);
                line-height: em(92px);
            }
        }

        .privacy {
            color: #b4b4b4;
            margin-top: 15px;

            &.underLine {
                text-decoration: underline;
            }
        }
    }
}
