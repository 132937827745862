// fonts
/** Sizes */
$baseFontSize: 16px;
$bp-1k: 120em;
$bp-2k: 160em;

/** Media Query */
$mq-2k: "(min-width: #{$bp-2k})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)";

html,
body {
    font-family: "Nunito Sans", sans-serif;
    font-size: em(16px);
    color: #343434;

    @media #{$mq-2k} {
        font-size: em(13px)*1.3;
    }
}

body {
    padding-top: 51px;

    @include media-breakpoint-up(md) {
        padding-top: 70px;
    }

}

main {
    position: relative;
}

/** Transition time */
$transitionTime: 0.5s;

// Colors Brand

$brand-primary: #ed1b2f;
$brand-secondary: #343434;
$brand-third: #191919;
$brand-alternative: #848484;
$brand-light: white;

//recall
$yellow00: #ffae00;
$gray0c: #0c0c0c;
$red1f: #e0141f;
$gray32: #323232;
$grayf7: #f7f7f7;
$graydc: #dcdcdc;

// auxilar colors
$aux-gray: #ececec;
$aux-gray1:#f6f6f6;
$aux-gray2:#f9f9f9;
$aux-gray3:#848484;
$aux-gray4:#e8e8e8;
$aux-gray5:#f8f8f8;
$aux-gray6:#e4e4e4;
$aux-dark: #191919;

//vehicules colors
$veh-service:#f2f2f2;

//Shadow
$shadow-1: rgba(0, 0, 0, 0.09);

//Background colors
$bgColor-1: black;
$bgColor-2: white;
$gbColor-3: #ed1c30;

//Font Colors

$ftColor-2: black;
$ftColor-4: #5b5b5b;
$ftColor-5: transparent;
$ftColor-6: #e2e2e2;

//Border Colors
$borderColor-1: #f1f1f1;

//Icons
$icon-play: url(/images/play.svg);
$icon-close-modal: url('/images/gallery/controls/close.png');
$icon-close-modal_lg: url('/images/gallery/controls/close-lg.png');
