//zoom out to image in viewport
.scaleImg {
    img {
        &:first-child {
            transform: scale(1.2);
            transition: transform 500ms ease;
        }
    }
    &.scaleImgIn {
        img {
            &:first-child {
                transform: scale(1);
            }
        }
    }
}

//horizontal mask
.maskH {
    position: relative;
    overflow: hidden;
    &::before {
        background-color: white;
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transform: translateX(0);
        transition: transform 500ms ease;
    }
    &.maskHIn {
        &::before {
            transform: translateX(100%);
        }
    }
}

//fade sequence
.fadeSeq {
    .itemToFade {
        opacity: 0;
        transform: translateY(40px);
        transition: all 500ms ease;
    }
    &.fadeSeqIn {
        .itemToFade {
            opacity: 1;
            transform: translateY(0);
            &:nth-child(2) {
                transition-delay: 500ms;
            }
            &:nth-child(3) {
                transition-delay: 750ms;
            }
            &:nth-child(4) {
                transition-delay: 1s;
            }
        }
    }
}