.openGuatemalaQuoteForm {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 20px;
    }
}

.main-wrap-quotation-guatemala-form {

    #quotation-guatemala-form {
        @include media-breakpoint-up(lg) {
            padding-left: 10%;
            padding-right: 10%;
            padding-bottom: 10%;
        }
    }

    .title {
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            font-size: em(60px);
        }
    }

    .wrap-input {
        margin-bottom: 10px;

        @media (min-width: 1500px) {
            margin-bottom: 20px;
        }

        label {
            text-align: left;
            display: block;
        }

        input {
            @media (min-width: 1500px) {
                height: 55px;
            }
        }

        select {
            background: url(/images/arrow-down-b.svg) #fbfbfb no-repeat 95% center;
            width: 100%;
            height: 50px;
            border-radius: 4px;
            border: solid 1px #dedede;
            box-shadow: inset 0 1px 7px 0 rgb(0 0 0 / 13%);
            color: #ed1b2f;
            -moz-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            font-size: 1.375em;
            padding-left: 20px;
            text-transform: uppercase;
            font-size: 1em;

            @media (min-width: 1500px) {
                height: 55px;
            }
        }

        .inputError {
            border: 2px solid $brand-primary;
        }

        .error {
            font-size: 14px;

            @include media-breakpoint-up(lg) {
                margin-top: 15px;
            }
        }
    }

    .back-detail {
        background-image: url(/images/extras/guatemala/back-form-m.jpg);
        background-size: cover;
        background-position: center center;
        min-height: 250px;
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            background-image: url(/images/extras/guatemala/back-form.jpg);
            position:relative;
            margin-top: 0;
        }

        .line {
            background-color: $brand-primary;
            height: 45%;
            width: 10px;
            position: absolute;
            left: auto;
            right: -10px;
            bottom: 0;
            top: auto;
        }
    }

}

.guatemala-popup {
    padding-bottom: 0 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;

    @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
        min-width: 800px;
    }

    @media (min-width: 1500px) {
        min-width: 1400px;
    }

    .swal2-close {
        display: block !important;
    }

}
